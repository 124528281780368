<template>
<div class="lesson-templates">
  <h1 class="title grey--text">结课 </h1>
  <!-- <div>
    <span>动态优惠费率: </span>
    <v-chip
      v-if="organizationInfo"
      class="ma-2"
      color="primary"
      text-color="white"
      @click="$router.push({name: 'membership'})"
    >
      {{organizationInfo.membership_level_code}}级会员
      <v-icon right>info</v-icon>
    </v-chip>
  </div> -->
  <div v-if="lessonSessions.length === 0" class="subtitle-2 text--secondary">
    暂没有需要结课的场次
  </div>
  <v-container class="my-5">
    <v-card
      v-for="(lessonSession, lessonSessionIndex) in lessonSessions" v-bind:key="`lesson-session-${lessonSessionIndex}`"
      class="px-4 my-4"
    >
      <v-card-title>
        <v-layout row wrap>
          <v-flex xs12 md4>
            <div class="caption grey--text"> 活动 </div>
            <div class="subtitle-2 font-weight-bold"> {{ lessonSession.title }} </div>
          </v-flex>
          <v-flex xs6 md4>
            <div class="caption grey--text"> 班级 </div>
            <div class="subtitle-2"><v-icon>weekend</v-icon> {{ lessonSession.class }} </div>
          </v-flex>
          <v-flex xs6 md2>
            <div class="caption grey--text"> 时间 </div>
            <div class="subtitle-2"><v-icon>schedule</v-icon> {{ lessonSession.time | moment("calendar") }}</div>
          </v-flex>
          <v-flex xs12 md2 class="d-flex flex-row justify-center">
            <v-dialog v-model="lessonSession.dialog" max-width="600px" persistent>
              <template v-slot:activator="{ on }">
                <v-btn
                  :loading="lessonSession.btnLoading"
                  :disabled="lessonSession.btnLoading"
                  @click="finalize(lessonSession.id, lessonSessionIndex)"
                  rounded
                  color="green"
                  class="my-2"
                  outlined
                  small
                >
                  <span class="">结课</span><v-icon right>check_circle</v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-title>
                  <span class="headline">结课: {{ lessonSession.title }}({{lessonSession.class}} | {{ lessonSession.time | moment("calendar") }})</span>
                </v-card-title>

                <v-card-text>
                  <v-progress-linear
                    indeterminate
                    color="red"
                    class="mb-0"
                    v-if="!lessonSession.finalizationResultMsg"
                  ></v-progress-linear>
                  <p v-if="lessonSession.finalizationResultMsg">
                    {{lessonSession.finalizationResultMsg}}
                  </p>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn dark color="red" @click="lessonSession.dialog = false">确定</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-flex>
        </v-layout>
      </v-card-title>
    </v-card>
  </v-container>
  <!-- display overlay on the whole page while loading -->
  <v-overlay :value="$store.state.queryingState">
    <v-progress-circular
      indeterminate
      color=""
    ></v-progress-circular>
  </v-overlay>
</div>
</template>

<script>
import LessonService from '@/services/LessonService'

import Vue from 'vue'
// import util from '@/util'

export default {
  components: {
  },
  data () {
    return {
      tutorId: null,
      organizationId: null,
      programs: [],
      programIds: [],
      lessonSessions: [],
      accountOrganizations: [],
      organizationInfo: {}
    }
  },
  watch: {
    async $route (to, from) {
      if (this.$route.query.organizationId || this.$route.query.tutorId) { // 如果存在任何路径参数，则优先考虑
        console.log('检测到路径参数变化')
        this.resetData()
        // set queryingState
        this.$store.dispatch('setQueryingState', true)
        // detect query
        if (this.$route.query.organizationId || this.$route.query.tutorId) { // 如果存在任何路径参数，则优先考虑
          if (this.$route.query.organizationId) {
            this.organizationId = this.$route.query.organizationId
          }
          if (this.$route.query.tutorId) {
            this.tutorId = this.$route.query.tutorId
          }
          console.log('成功获取路径参数！')
        }
        // Get on-going lesson session by tutorId or organizationId
        this.lessonSessions = await this.getLessonSessionsToBeFinalized({
          tutorId: this.tutorId,
          organizationId: this.organizationId
        })
        console.log('Retrieved lesson sessions: ', this.lessonSessions)
        this.lessonSessions.forEach(element => {
          Vue.set(element, 'btnLoading', false)
          Vue.set(element, 'finalizationResultMsg', '')
          Vue.set(element, 'msg', '')
          Vue.set(element, 'dialog', false)
        })

        // set queryingState
        this.$store.dispatch('setQueryingState', false)
      }
    }
  },
  async mounted () {
    // set queryingState
    this.$store.dispatch('setQueryingState', true)

    // detect query
    if (this.$route.query.organizationId || this.$route.query.tutorId) { // 如果存在任何路径参数，则优先考虑
      if (this.$route.query.organizationId) {
        this.organizationId = this.$route.query.organizationId
      }
      if (this.$route.query.tutorId) {
        this.tutorId = this.$route.query.tutorId
      }
      console.log('成功获取路径参数！')
    }
    // Get on-going lesson session by tutorId or organizationId
    this.lessonSessions = await this.getLessonSessionsToBeFinalized({
      tutorId: this.tutorId,
      organizationId: this.organizationId
    })
    console.log('Retrieved lesson sessions: ', this.lessonSessions)
    this.lessonSessions.forEach(element => {
      Vue.set(element, 'btnLoading', false)
      Vue.set(element, 'finalizationResultMsg', '')
      Vue.set(element, 'msg', '')
      Vue.set(element, 'dialog', false)
    })

    // // get tutor Id
    // this.tutorId = this.$store.state.account.tutor.id
    // console.log('Fetch tutorId from state.account.tutor.id: ', this.tutorId)

    // // get organization and membership info
    // this.accountOrganizations = this.$store.state.account.organizations
    // if (this.accountOrganizations.length > 0) {
    //   let res = await OrganizationService.getOrganizationInfo({ organizationId: this.accountOrganizations[0].id })
    //   this.organizationInfo = res.data

    //   // Get on-going lesson session by tutorId
    //   let statusCodes = [1]
    //   this.lessonSessions = await this.getLessonSessionsbyTutorId(this.tutorId, statusCodes)
    //   console.log('Retrieved lesson sessions: ', this.lessonSessions)
    //   this.lessonSessions.forEach(element => {
    //     Vue.set(element, 'btnLoading', false)
    //     Vue.set(element, 'finalizationResultMsg', '')
    //     Vue.set(element, 'msg', '')
    //     Vue.set(element, 'dialog', false)
    //   })
    // } else {
    //   console.log('该角色不具有机构账号!')
    // }

    // set queryingState
    this.$store.dispatch('setQueryingState', false)
  },
  methods: {
    resetData () {
      this.tutorId = null
      this.organizationId = null
      this.programs = []
      this.programIds = []
      this.lessonSessions = []
      this.accountOrganizations = []
      this.organizationInfo = {}
    },
    async finalize (lessonSessionId, index) {
      console.log('Finalize button clicked for lessonSessionId: ', lessonSessionId)
      this.lessonSessions[index].btnLoading = true
      this.lessonSessions[index].dialog = true
      console.log('Start lessonService.lessonIncomeAllocation ... ')
      const res = await LessonService.lessonIncomeAllocation(lessonSessionId)
      console.log(res)
      this.lessonSessions[index].btnLoading = false
      this.lessonSessions[index].finalizationResultMsg = res.data.msg
      console.log('successful! res: ', res)
      if (parseInt(res.data.errorCode) === 0) {
        console.log('Successful!')
        this.$router.go()
      } else {
        console.log(res.data.msg)
      }
    },
    async getLessonSessionsbyTutorId (tutorId, statusCodes) {
      const res = await LessonService.getLessonSessions({
        tutorIds: [this.tutorId],
        statusCodes: statusCodes
      })
      console.log('lessonSessions:', res.data)
      return res.data
    },
    async getLessonSessionsToBeFinalized ({ tutorId, organizationId }) {
      let argument = {}
      argument.statusCodes = [1]
      if (tutorId) argument.tutorIds = [tutorId]
      if (organizationId) argument.organizationIds = [organizationId]
      const res = await LessonService.getLessonSessions(argument)
      console.log('lessonSessions:', res.data)
      return res.data
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
